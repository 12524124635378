import React, {useEffect, useState} from "react";
import {Box, Chip, Stack, Typography} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import axios from "../../axios/axios";
import ReactJson from "react-json-view";

export const DebugInfo = ({message}) => {

    const [open, setOpen] = useState(false);
    const [log, setLog] = useState(null);

    useEffect(() => {
        if (message) {
            axios.get(`/api/message/${message?.id}/log`)
                .then(response => {
                    setLog(response.data);
                })
                .catch((error) => console.log(error));
        }
    }, [message])

    return (
        log && log?.body ?
            <Stack direction={"column"} gap={1} sx={{height: "100%"}}>
                {
                    log && log?.body &&
                    <ReactJson src={JSON.parse(log?.body)} theme={"bright:inverted"} style={{height: "100%"}} />
                }
            </Stack>
            :
            message ?
                <Typography fontSize={14}>There is no information for this message</Typography>:
                <Typography fontSize={14}>Select message</Typography>


    )
}

const IntentWordCloud = ({message}) => {

    const words = [
        {
            text: 'Aktivacija tarife',
            value: 0.93,
        },
        {
            text: 'Preporuka tarife',
            value: 0.5,
        },
        {
            text: 'Obnova bonom',
            value: 0.02,
        },
    ]

    return <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
        { words?.map(item => {
            let color = red[500];
            if (item?.value >= 0.75) {
                color = green[400]
            } else if (item?.value >= 0.5) {
                color = orange[500]
            }

            return <Chip sx={{bgcolor: color, color: "#FFF"}} size={"small"} label={<Typography fontSize={11}>{item?.text} {item?.value?.toFixed(2)}</Typography>} />
        })}
        {/*<ReactWordcloud*/}
        {/*    words={words}*/}
        {/*    callbacks={{*/}
        {/*        getWordColor: (word) => word?.value >= 0.75 ? "green" : word?.value >= 0.5 ? "orange" : "red",*/}
        {/*        getWordTooltip: (word) => `${word?.text} [${word?.value}]`*/}
        {/*    }}*/}
        {/*    options={{*/}
        {/*        rotations: 3,*/}
        {/*        rotationAngles: [0, 45, -45],*/}
        {/*    }}*/}
        {/*/>*/}
    </Stack>
}