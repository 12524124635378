import './App.css';
import {CircularProgress, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes, BrowserRouter, useSearchParams} from "react-router-dom";
import {LoginPage} from "./pages/Login/LoginPage";
import {Chats} from "./pages/Chat/Chats";
import {useEffect, useState} from "react";
import axios, {AUTH_TOKEN} from "./axios/axios";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      // default: "#212121"
    },
    light: {
      main: "#FFF"
    }
  },
});

function App() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    if (searchParams.has("json_url")){
      const json_url = searchParams.get("json_url");
      console.log(json_url);
      localStorage.setItem("json_url", json_url);
    }
  }, [searchParams])

  useEffect(() => {
    axios.post(`/api/user/me`)
      .then(response => {
        const auth = response?.data;
        setIsAuth(auth);
        if (!auth) {
          localStorage.removeItem(AUTH_TOKEN)
          // window.location.reload()
        }
      })
      .catch(error => setIsAuth(false));
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        hideProgressBar
        newestOnTop
      />

      {
        isAuth === null ?
          <div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress/></div> :
          <Routes>
            {
              !isAuth ?
                <>
                  <Route path={"/login"} element={<LoginPage/>}/>
                  <Route path={"*"} element={<Navigate to={"/login"}/>}/>
                </>
                :
                <>
                  <Route exact path={"/"} element={<Chats/>}/>
                  <Route exact path={"/chat/:id"} element={<Chats/>}/>

                  <Route path={"*"} element={<Navigate to={"/"}/>}/>
                </>
            }
          </Routes>
      }

    </ThemeProvider>
  );
}

export default App;
