import React, {useMemo, useRef, useState} from "react";
import {
    Avatar,
    Box,
    Button, Drawer,
    Fade,
    IconButton,
    List,
    ListItemButton,
    Stack, Toolbar,
    Typography
} from "@mui/material";
import logo from "../../assets/logo.svg"
import {Link, NavLink} from "react-router-dom";
import {Add, FiberManualRecord, Logout, Menu} from "@mui/icons-material";
import {AUTH_TOKEN} from "../../axios/axios";
import {red} from "@mui/material/colors";

const drawerWidth = 260

export const MainLayout = ({children, items, mapItem, primary, secondary}) => {
    const [isOpen, setIsOpen] = useState(false);
    const headerRef = useRef();

    const logout = () => {
        localStorage.removeItem(AUTH_TOKEN);
        window.location.reload();
    }

    const height = useMemo(() => {
        let val = 0;
        if (headerRef.current) {
            val = headerRef.current.offsetHeight || 0;
        }
        return val;
    }, [headerRef.current])


    return <>
        <Toolbar id={"header"} ref={headerRef} sx={{bgcolor: "#FFF"}}>
            <Stack direction={"row"}
                   sx={{width: "100%", pt: 1}} gap={1}
                   justifyContent={"space-between"}
                   alignItems={"center"}>
                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={1}>
                    <Stack direction={"row"}
                           sx={{width: "100%"}}
                           justifyContent={isOpen ? "space-between" : "center"}
                           alignItems={"center"}>
                        <img src={logo} alt={"Logo"} width={50} height={"auto"}/>
                        <Box sx={{px: 1, display: {xs: 'none', sm: "block"}}}>
                            <Typography fontSize={15}>OpenBrain</Typography>
                            <Typography fontSize={8} textTransform={"uppercase"} fontWeight={"bold"}>voice
                                bot</Typography>
                        </Box>

                        <IconButton onClick={() => setIsOpen(!isOpen)}>
                            <Menu/>
                        </IconButton>
                    </Stack>
                    {/*<UserInfo />*/}
                </Stack>

                <Box sx={{display: {xs: 'none', sm: "flex"}}}>
                    {primary}
                </Box>

                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    {secondary}

                    <IconButton
                        sx={{mx: 2, display: {xs: "none", sm: "flex"} }}
                        onClick={logout}
                    >
                        <Logout/>
                    </IconButton>
                </Stack>
            </Stack>
        </Toolbar>
        <Stack direction="row" sx={{height: `calc(100% - ${height}px)`}}>
            <Drawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                variant={"temporary"}
                sx={{
                    width: `${drawerWidth}px !important`,
                    border: 0,
                    height: "100%",
                    overflowY: "auto",
                    boxShadow: 0,
                    zIndex: 1,
                    py: 1,
                    px: 2,
                    '& .MuiDrawer-paper': {
                        border: 0,
                        boxShadow: 0,
                        zIndex: 0,
                        width: `${drawerWidth}px !important`,
                    },
                }}
            >
                <Toolbar>
                    <Stack direction={"row"}
                           sx={{width: "100%"}}
                           justifyContent={isOpen ? "space-between" : "center"}
                           alignItems={"center"}>
                        <img src={logo} alt={"Logo"} width={50} height={"auto"}/>
                        <Box sx={{px: 1, display: {xs: 'none', sm: "block"}}}>
                            <Typography fontSize={15}>OpenBrain</Typography>
                            <Typography fontSize={8} textTransform={"uppercase"} fontWeight={"bold"}>voice
                                bot</Typography>
                        </Box>

                        <IconButton onClick={() => setIsOpen(!isOpen)}>
                            <Menu/>
                        </IconButton>
                    </Stack>
                </Toolbar>
                <Box sx={{p: 1}}>

                    <Button
                        component={Link}
                        to={"/"}
                        sx={{
                            color: "white",
                            borderRadius: 2,
                            fontSize: 12,
                            background: red[200],
                            '&:hover': {background: red[300]}
                        }}
                        fullWidth
                        variant={"contained"}
                    >
                        <Add sx={{fontSize: 17}}/>{isOpen &&
                        <Typography fontSize={12}> Create
                            new</Typography>}
                    </Button>

                    <List>
                        {
                            items?.map(item => {
                                return mapItem !== undefined ?
                                    mapItem(item) :
                                    <ListItemButton
                                        key={item?.id}
                                        sx={{borderBottom: 1, borderColor: "divider", mb: 1}}
                                        component={NavLink} to={`/${item?.route}`}
                                    >
                                        {item?.name}
                                    </ListItemButton>
                            })
                        }
                    </List>
                </Box>

            </Drawer>
            <Box sx={{height: "100%", width: "100%", px: 2}}>
                <Box sx={{maxHeight: `calc(100vh - ${height}px)`, height: "100%", width: "100%", py: 1}}>
                    {children}
                </Box>
            </Box>
        </Stack>
    </>
}


const UserInfo = () => {
    return (
        <Stack
            direction={"column"}
            gap={1}
            sx={{borderBottom: 1, borderColor: "divider", width: "100%"}}
        >
            <Avatar
                sx={{width: 76, height: 76, mx: "auto"}}
            ></Avatar>
            <Typography sx={{fontSize: 12}} align={"center"} variant={"subtitle2"} textOverflow={"ellipsis"}>
                {localStorage?.getItem(AUTH_TOKEN)?.substr(0, 20) || ""}
            </Typography>
            <IconButton sx={{mx: "auto", textAlign: "center"}}>
                <Logout/>
            </IconButton>
        </Stack>
    )
}