import React, {useState} from "react";
import {Button, Divider, Grid, TextField, Typography} from "@mui/material";
import logo from "../../assets/logo.svg";
import axios, {AUTH_TOKEN} from "../../axios/axios";

export const LoginPage = () => {
    const [password, setPassword] = useState();
    const [error, setError] = useState(null);

    const loginAttempt = (pass) => {
        axios.post('/api/user/login', {password: pass})
            .then(response => {
                if (response?.data?.error) {
                    // setError(response.data.error)
                    return;
                }
                localStorage.setItem(AUTH_TOKEN, response?.data?.token);
                window.location.reload();
            })
            .catch(error => {
                setError(error.message)
            })
    }

    const loginAnonymous = () => {
        const time= Date.now();
        loginAttempt(time);
    }

    return (
        <Grid container xs={12} justifyContent={"center"} alignItems={"center"} sx={{width: "100%", height: "100%"}}>
            <Grid item gap={2} sx={{borderColor: "divider", p: 3, textAlign: "center"}}>
                <img src={logo} alt={"logo"} width={"120px"} height={"auto"} />
                <Typography sx={{my: 2}} variant={"h5"}>Welcome to Openbrain chatbot</Typography>
                <Typography sx={{my: 2}} variant={"subtitle2"}>Please enter a password</Typography>
                <TextField
                    label={"Password"}
                    type={"password"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <Button
                    sx={{my: 2}}
                    fullWidth
                    variant={"contained"}
                    onClick={() => loginAttempt(password)}
                >
                    Login
                </Button>

                <Typography color={"error"}>
                    {error}
                </Typography>

                <Divider>
                    OR
                </Divider>

                <Button
                    fullWidth
                    variant={"text"}
                    size={"small"}
                    sx={{my: 2, color: "primary", textTransform: "lowercase"}}
                    onClick={loginAnonymous}
                >
                    Login anonymous
                </Button>
            </Grid>
        </Grid>
    )
}