import React, {useEffect, useMemo, useRef, useState} from "react";
import axios from "../../axios/axios";
import {
    Avatar,
    Box,
    Chip, CircularProgress, Fade,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {CallEnd, CloseFullscreen, Mic, MicOff, Person, Send, VolumeMute, VolumeUp} from "@mui/icons-material";
import {teal, red, grey} from "@mui/material/colors";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWebSocket from "../../hooks/useWebSocket";
import {AudioRecorder} from "./AudioRecorder";
import {useAudioPlayer} from "../../hooks/useAudioPlayer";
import {DebugInfo} from "./DebugInfo";
import logo from "../../assets/logo.svg"
import useDebounce from "../../hooks/useDebounce";
import {convertHMS} from "../../utils/convertToHMS";


const debugDrawerWidth = 550

export const Chat = ({
                         id,
                         time,
                         selectedMessage,
                         classicView,
                         onSelect,
                         isMuted,
                         addChat,
                         debugMode,
                         isCall,
                         endCall,
                         isMicrophoneMuted,
                         onMuteMicrophone,
                         onClassicalView,
                         onMuteAudio
                     }) => {
    const bottomRef = useRef(null);
    const textareaRef = useRef(null);
    const navigate = useNavigate();
    const [updatedValue, state] = useWebSocket(`/topic/session/`, id);

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [sendMessageBuffer, setSendMessageBuffer] = useState([]);
    const [audioMessage, setAudioMessage] = useState("");
    const audioMessageDebounced = useDebounce(audioMessage, 2000);
    const messageDebounced = useDebounce(message, 2000);
    const sendMessageBufferDebounced = useDebounce(sendMessageBuffer, 2000);
    const [buttons, setButtons] = useState([])
    const [loading, setLoading] = useState(false);
    const [newMessage, setNewMessage] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const messageTextFieldRef = useRef(null);

    const [currentAudioMessage, addAudio, onFinishAudio, forcePlay] = useAudioPlayer(isMuted);

    useEffect(() => {
        const fetchData = () => {
            axios.get(`/api/message/session/${id}`)
              .then(response => {
                  setMessages(response.data);
                  const lastMessage = response.data[response?.data.length - 1];
                  if (lastMessage && lastMessage.buttons) {
                      setButtons(lastMessage?.text?.split(";"));
                  }
              })
              .catch(error => {
                  console.log(error);
              })
        }
        setMessages([]);
        setButtons([]);
        setMessage("");
        let timer = null;

        if (id) {
            fetchData()
            if (state === "error") {
                // timer = setInterval(() => {
                //     fetchData();
                // }, 3000);
            }
        }

        return () => {
            if (timer) {
                // clearInterval(timer);
            }
        };
    }, [id])

    useEffect(() => {
        if (updatedValue) {
            if (updatedValue && +id === +updatedValue?.session) {
                setNewMessage(updatedValue.message);
            }
        }
    }, [updatedValue])

    useEffect(() => {
        if (newMessage) {
            const updateNewMessage = () => {
                const promise = new Promise((resolve, reject) => {
                    const updateMessage = {...newMessage};
                    const newMessages = [...messages];
                    const index = newMessages.findIndex(i => i?.id === updateMessage?.id);
                    if (isFinite(index) && index >= 0) {
                        newMessages[index] = updateMessage;
                    } else {
                        newMessages.push(updateMessage);
                    }
                    const items = newMessages.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0);
                    let buttonItems = [];
                    const lastMessage = items[items.length - 1];
                    if (lastMessage && lastMessage.buttons) {
                        buttonItems = lastMessage?.text?.split(";");
                    }
                    setButtons(buttonItems);
                    if (sendMessageBuffer.length){
                        const buffered = [...sendMessageBuffer].filter(i => i !== updateMessage.text);
                        if (buffered.length){
                            setSendMessageBuffer(buffered);
                        }
                    }
                    if (!isMuted && updateMessage?.audio) {
                        addAudio(updateMessage);
                    }
                    if (updatedValue?.message?.id === updateMessage?.id){
                        setNewMessage(null);
                    }

                    resolve(items)
                })
                promise.then(value => setMessages(value)).catch(err => console.log(err));
            }
            updateNewMessage();
        }
    }, [newMessage])

    useEffect(() => {
        if (loading === false && textareaRef && textareaRef.current) {
            textareaRef.current.focus()
        }
    }, [loading])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        if (bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [messages, sendMessageBuffer]);

    useEffect(() => {
        if (audioMessageDebounced) {
            sendMessage(audioMessageDebounced);
        }
    }, [audioMessageDebounced])

    useEffect(() => {
        if (audioMessageDebounced === audioMessage && messageDebounced === message && sendMessageBufferDebounced.length) {
            const sendMessages = async () => {
                if (id && sendMessageBufferDebounced && !loading) {
                    setLoading(true);
                    try {
                        const data = {
                            session: id,
                            texts: [...sendMessageBufferDebounced],
                            json: localStorage.getItem("json_url") || null
                        }
                        setSendMessageBuffer(prev => {
                            return prev.filter(m => !data.texts.includes(m));
                        });
                        const response = await axios.post(`/api/message/multiple`, data)

                        setMessages(prevState => {
                            const items = [...prevState];
                            for (const item of response.data) {
                                const index = items.findIndex(i => i.id === item.id);
                                if (isFinite(index) && index >= 0) {
                                    items[index] = item;
                                } else {
                                    items.push(item);
                                }
                            }
                            return items.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0)
                        });
                    } catch (e) {
                        setMessages([]);
                        setSendMessageBuffer([]);
                        setMessage("");
                        setButtons([])
                    } finally {
                        setLoading(false);
                        setIsRecording(false);
                    }

                }
                else if (message) {
                    setLoading(true);
                    try {
                        const response = await axios.post(`/api/session`, {text: message})
                        setMessages([]);
                        setMessage("");
                        setButtons([])
                        navigate(`/chat/${response?.data?.id}`);
                        addChat(response.data);
                    } catch (e) {
                        console.log(e)
                    } finally {
                        setLoading(false);
                        setIsRecording(false);
                    }
                }
            }
            sendMessages();
        }
    }, [audioMessageDebounced, messageDebounced, sendMessageBufferDebounced])

    const sendMessage = (message) => {
        if (message) {
            setSendMessageBuffer(prev => {
                return [...prev, message];
            })
            if (message) {
                setMessage("");
            }
            if (audioMessage) {
                setAudioMessage("")
            }
        }
    }

    const onSelectHandle = (item) => {
        onSelect(item);
        forcePlay(item);
    }

    const audio = useMemo(() => {
        return <audio
          src={currentAudioMessage?.audio || ""}
          autoPlay
          muted={isMuted}
          onEnded={() => onFinishAudio()}
        />
    }, [currentAudioMessage, isMuted]);


    return (
      <Stack direction={"column"} gap={1}
             sx={{height: '100%', maxHeight: "100%"}}
             justifyContent={"space-between"}
             alignItems={"center"}>
          {audio}
          <AgentCall
            open={isCall}
            onClose={endCall}
            classicView={classicView}
            agentMessage={newMessage}
            userMessage={audioMessage}
            time={time}
            isAudioMuted={isMuted}
            isMicrophoneMuted={isMicrophoneMuted}
            onMuteMicrophone={onMuteMicrophone}
            onClassicalView={onClassicalView}
            onMuteAudio={onMuteAudio}
          />
          <Stack direction={"row"} flexGrow={1} sx={{width: "100%", px: 1, overflowY: "auto"}}>
              <Box sx={{width: "100%", maxHeight: `100%`, px: 1, overflowY: 'auto'}}>
                  <Stack direction={"column"} gap={2} sx={{width: "100%", pt: 1}}>
                      {
                          messages?.map(item => {
                              const exactDateTimestamp = item?.id;

                              const isAgent = item?.sender === "AGENT"
                              const selected = selectedMessage?.id === item.id ? 200 : 0;
                              return item?.buttons === false ? <Stack key={exactDateTimestamp} id={exactDateTimestamp}
                                                                      direction={isAgent ? "row" : "row-reverse"}
                                                                      gap={1}>
                                  <Avatar
                                    sx={{
                                        bgcolor: isAgent ? teal[50] : red[200 + selected],
                                        border: 0,
                                        color: "#FFF",
                                        borderColor: "#000",
                                        cursor: "pointer",
                                        boxShadow: selected ? 10 : 0
                                    }}
                                    onClick={() => onSelectHandle(item)}
                                  >
                                      {
                                          isAgent ?
                                            <img src={logo} width={30} alt={"logo"} height={"auto"}/>
                                            :
                                            <Person sx={{fontSize: 25}}/>
                                      }
                                  </Avatar>
                                  <Box
                                    sx={{
                                        bgcolor: isAgent ? teal[200 + selected] : red[200 + selected],
                                        color: "#FFF",
                                        px: 2,
                                        py: 1,
                                        borderRadius: 3,
                                        cursor: "pointer",
                                        boxShadow: selected ? 10 : 0
                                    }}
                                    onClick={() => onSelectHandle(item)}
                                  >
                                      <Typography fontSize={12} dangerouslySetInnerHTML={{__html: item?.text}}/>
                                      <Typography
                                        fontSize={9}>{moment(item?.dateCreated).fromNow()}</Typography>
                                  </Box>
                                  <Avatar sx={{opacity: 0}}/>
                              </Stack> : null
                          })
                      }
                      {
                          sendMessageBuffer?.map((messegeBuffer, index) => {

                              return (
                                <Stack key={index} id={index}
                                       direction={"row-reverse"}
                                       gap={1}>
                                    <Avatar
                                      sx={{
                                          bgcolor: red[100],
                                          border: 0,
                                          color: "#FFF",
                                          borderColor: "#000",
                                          cursor: "pointer",
                                          boxShadow: 0
                                      }}
                                    >
                                        <Person sx={{fontSize: 25}}/>
                                    </Avatar>
                                    <Box
                                      sx={{
                                          bgcolor: red[100],
                                          color: "#FFF",
                                          px: 2,
                                          py: 1,
                                          borderRadius: 3,
                                          cursor: "pointer",
                                          boxShadow: 0
                                      }}
                                    >
                                        <Typography fontSize={12} dangerouslySetInnerHTML={{__html: messegeBuffer}}/>
                                    </Box>
                                    <Avatar sx={{opacity: 0}}/>
                                </Stack>
                              )
                          })
                      }
                      {
                        messages?.length > 0 && messages[messages?.length - 1]?.sender === "USER" &&
                        <Stack id={"loading-message"}
                               direction={"row"}
                               gap={1}>
                            <Avatar
                              sx={{
                                  bgcolor: teal[50],
                                  border: 0,
                                  color: "#FFF",
                                  borderColor: "#000",
                                  cursor: "pointer",
                                  boxShadow: 10
                              }}
                            >
                                <img src={logo} width={30} alt={"logo"} height={"auto"}/>
                            </Avatar>
                            <Box
                              sx={{
                                  bgcolor: teal[200],
                                  color: "#FFF",
                                  px: 1,
                                  pt: '5px',
                                  borderRadius: 3,
                                  cursor: "pointer",
                                  boxShadow: 10
                              }}
                            >
                                <Typography fontSize={12} sx={{textAlign: "center"}}>
                                        <span className="typing-dots">
                                            <span/><span/><span/>
                                        </span>
                                </Typography>
                            </Box>
                            <Avatar sx={{opacity: 0}}/>
                        </Stack>
                      }

                      <div ref={bottomRef}/>
                  </Stack>

              </Box>

              {
                debugMode &&
                <Box sx={{
                    width: '100%',
                    maxWidth: `${debugDrawerWidth}px`,
                    maxHeight: "100%",
                    borderLeft: 1,
                    borderColor: "divider",
                    px: 1,
                    overflowY: 'auto',
                }}>
                    <DebugInfo/>
                </Box>
              }
          </Stack>
          <Box ref={messageTextFieldRef} flexGrow={0}
               sx={{px: '0 !important', display: "block", borderColor: "divider", width: "100%"}}>
              {
                buttons &&
                <Stack direction={"row"} gap={1} sx={{my: 1, width: "100%"}} useFlexGap flexWrap={"wrap"}>
                    {buttons.map(button => <Chip key={button} size={"small"} sx={{fontSize: 12, bgcolor: grey[300]}}
                                                 onClick={() => sendMessage(button)} label={button}/>)}
                </Stack>
              }
              <Stack direction={"row"} sx={{width: "100%"}} gap={1} alignItems={"center"}>
                  <TextField
                    inputRef={textareaRef}
                    multiline
                    maxRows={3}
                    fullWidth
                    sx={{px: 0}}
                    autoFocus
                    placeholder={"Enter your message"}
                    value={audioMessage || message}
                    disabled={loading && audioMessage !== null}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey && message) {
                            e.preventDefault();
                            sendMessage(message);
                        }
                    }}
                  />
                  {/*{audioRecorder}*/}
                  <AudioRecorder
                    id={id}
                    updateMessage={(msg) => setAudioMessage(prevState => {
                        return `${prevState} ${msg}`
                    })}
                    isMicrophoneMuted={isMicrophoneMuted}
                    isCall={isCall}
                    pauseRecording={() => {
                        setIsRecording(false);
                        if (isMicrophoneMuted)
                            onMuteMicrophone();
                        if (isMuted) {
                            onMuteAudio();
                        }
                        endCall();
                    }}
                    isRecording={isRecording}
                  />
                  <IconButton
                    sx={{height: "100%"}}
                    type={"button"}
                    onClick={() => sendMessage(message)}
                    disabled={loading || message === ""}
                  >
                      <Send/>
                  </IconButton>
              </Stack>
          </Box>
      </Stack>
    )
}

const AgentCall = ({
                       open,
                       time,
                       classicView,
                       onClose,
                       agentMessage,
                       userMessage,
                       isAudioMuted,
                       isMicrophoneMuted,
                       onMuteMicrophone,
                       onClassicalView,
                       onMuteAudio
                   }) => {
    const [newMessage, setNewMessage] = useState("");
    const [talkingPerson, setTalkingPerson] = useState("USER");

    useEffect(() => {
        if (agentMessage && agentMessage?.buttons === false) {
            setTalkingPerson("AGENT");
            setNewMessage(agentMessage?.text);
        }
    }, [agentMessage])

    useEffect(() => {
        if (userMessage) {
            setTalkingPerson("USER");
            setNewMessage(userMessage);
        }
    }, [userMessage])

    return (
      <Fade in={open && !classicView} timeout={100}>
          <Box className={"call"}>
              <Stack direction={"column"} gap={2}
                     sx={{height: "100%"}} alignItems={"center"}
                     justifyContent={"center"}>
                  <Avatar sx={{
                      width: '150px',
                      height: '150px',
                      bgcolor: talkingPerson === "AGENT" ? teal[50] : red[200],
                  }}>
                      {
                          talkingPerson === "AGENT" ?
                            <img src={logo} width={120} alt={"logo"} height={"auto"}/>
                            :
                            <Person sx={{fontSize: 120}}/>
                      }
                  </Avatar>
                  <Typography fontSize={30} color={"white"} fontWeight={"bold"}
                              textTransform={"uppercase"}>{talkingPerson}</Typography>
                  <Typography component={"em"} fontSize={20} textAlign={"center"}
                              color={"white"}>"{newMessage}"</Typography>
                  <Stack direction={"column"} gap={2} alignItems={"center"}
                         sx={{width: "300px", p: 3, background: "rgba(0,0,0,0.2)", borderRadius: 2}}>
                      <Typography fontSize={20} sx={{color: grey[300]}}>{convertHMS(time)}</Typography>

                      <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "100%"}}>
                          <IconButton sx={{color: isAudioMuted ? grey[400] : grey[300]}}
                                      onClick={onMuteAudio}>
                              {isAudioMuted ? <VolumeMute sx={{fontSize: 35}}/> : <VolumeUp sx={{fontSize: 35}}/>}
                          </IconButton>
                          <IconButton sx={{color: isMicrophoneMuted ? grey[400] : grey[300]}}
                                      onClick={onMuteMicrophone}>{isMicrophoneMuted ? <MicOff sx={{fontSize: 35}}/> :
                            <Mic sx={{fontSize: 35}}/>}</IconButton>
                          <IconButton sx={{color: grey[300]}} onClick={onClassicalView}><CloseFullscreen
                            sx={{fontSize: 35}}/></IconButton>
                      </Stack>
                      <IconButton color={"error"}
                                  sx={{background: 'rgba(255,22,33, 0.1)', mx: "auto", textAlign: "center"}}
                                  onClick={onClose}><CallEnd sx={{fontSize: 40}}/></IconButton>
                  </Stack>
              </Stack>
          </Box>
      </Fade>
    )
}
