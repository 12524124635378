import {useEffect, useState} from "react";
import {Client} from "@stomp/stompjs";

const useWebSocket = (topic, id) => {
    // State and setters for debounced value
    const [updateValue, setUpdateValue] = useState(null);
    const [state, setState] = useState("inactive");

    useEffect(() => {
        if (topic && id) {
            let client = null;
            const url = topic + id;
            let onConnected = () => {
                // console.log(`Connected to websocket`)
                setState("connected")
                // console.log("URL: ", url, client)
                try {
                    client.subscribe(url, function (msg) {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setUpdateValue(jsonBody)
                            }
                        }
                    });
                } catch (error) {
                    setState("error");
                }

            }

            let onDisconnected = () => {
                // console.log("Disconnected!!")
                setState("disconnected")
            }

            let SOCKET_URL = process.env.REACT_APP_WS;
            client = new Client({
                brokerURL: SOCKET_URL,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: onConnected,
                onDisconnect: onDisconnected,
                onStompError: () => {
                    // console.log(`Failed to connect to topic (Stomp error): ${SOCKET_URL}`)
                    setState("error")
                },
                onWebSocketError: () => {
                    // console.log(`Failed to connect to topic (web socket error): ${SOCKET_URL}`);
                    setState("error");
                }
            });

            client.activate();

            return () => {
                if (client) {
                    // client?.disconnect();
                    client = null;
                }
            }
        }
    }, [topic, id])

    return [updateValue, state];
};

export default useWebSocket;