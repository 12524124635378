import React, {useEffect, useState} from "react";
import {Mic, MicOff, Stop} from "@mui/icons-material";
import {Avatar, Box, IconButton, Stack, Typography} from "@mui/material";

const StateType = {
    paused: "paused",
    recording: "recording",
    error: "error"
}

let bufferSize = 1024

export const AudioRecorder = ({id, updateMessage, isRecording, pauseRecording, isCall, isMicrophoneMuted}) => {
    const [state, setState] = useState(isRecording ? StateType.recording : StateType.paused);
    const [connection, setConnection] = useState(false);
    const [websocket, setWebsocket] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [input, setInput] = useState(null);
    const [processor, setProcessor] = useState(null);
    const [context, setContext] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [globalStream, setGlobalStream] = useState(null);

    useEffect(() => {
        if (id && connection === false) {
            initWebSocket();
        }
        return () => {
            if (websocket) {
                websocket?.close();
            }
        }
    }, [id, connection]);

    useEffect(() => {
        if (websocket) {
            isCall ? startRecording() : stopRecording();
        }
    }, [isCall])

    useEffect(() => {
        if (context) {
            if (isMicrophoneMuted) {
                context.suspend().then(() => console.log("CONTEXT PAUSED"));
            } else {
                context.resume()?.then(() => console.log("CONTEXT RESUMED"));
            }
        }

    }, [isMicrophoneMuted])

    const initWebSocket = () => {
        const websocket = new WebSocket(process.env.REACT_APP_VAD_WS);

        // WebSocket Definitions: executed when triggered webSocketStatus
        websocket.onopen = function () {
            console.log("connected to server");
            setConnection(true);
            if (isCall) {
                startRecording();
            }
            //websocket.send("CONNECTED TO YOU");
        }

        websocket.onclose = function (e) {
            console.log("connection closed (" + e.code + ")");
            setConnection(false);
            pauseRecording();
            setState(StateType.paused);
        }

        websocket.onmessage = function (e) {
            //console.log("message received: " + e.data);
            let result = undefined;
            try {
                updateMessage(e.data)
                // return String we dont need to parse it
                // result = JSON.parse(e.data);
                // TODO (Here we need to update message state in Chat and CreateChat
            } catch (e) {
                console.log(e, "error on parse")
            }

            if (typeof (result) !== 'undefined' && typeof (result.error) !== 'undefined') {
                console.log(result.error)
            }
        }
        setWebsocket(websocket);
    }

    function downsampleBuffer(buffer, sampleRate, outSampleRate) {
        if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw 'downsampling rate show be smaller than original sample rate';
        }
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
            var accum = 0,
                count = 0;
            for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    }

    const startRecording = async () => {
        try {
            // if (!stream) {
            //     await register(await connect());
            // }
            if (!websocket) {
                return;
            }
            AudioContext = window.AudioContext || window.webkitAudioContext;
            const context = new AudioContext({
                // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
                latencyHint: 'interactive',
            });

            setAudioContext(context);
            const processor = context.createScriptProcessor(bufferSize, 1, 1);
            processor.connect(context.destination);
            context.resume();
            setProcessor(processor);
            var handleSuccess = function (stream) {
                setGlobalStream(stream);

                let input = context.createMediaStreamSource(stream);
                input.connect(processor);
                setInput(input);

                processor.onaudioprocess = function (e) {
                    var left = e.inputBuffer.getChannelData(0);
                    var left16 = downsampleBuffer(left, 48000, 16000);
                    if (websocket && websocket?.readyState === 1 && isMicrophoneMuted === false) {
                        websocket.send(left16);
                    }
                };
            };

            setContext(context);

            navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(handleSuccess);

            setState('recording')
        } catch (error) {
            pauseRecording();
            setState(StateType.error);
        }
    };

    const stopRecording = () => {
        if (globalStream && globalStream?.getTracks().length > 0) {
            try {
                setState(StateType.paused);
                let track = globalStream.getTracks()[0];
                track.stop();

                websocket?.close();
                input.disconnect(processor);
                processor.disconnect(context.destination);
                context.close()
                    .then(function () {
                        console.log("Context closed")
                    })
                    .catch(error => console.log(error));
            } catch (error) {
                console.log(error, "ON STOP");
            } finally {
                setInput(null);
                setProcessor(null);
                setContext(null);
                setAudioContext(null);
                setConnection(false);
                setState(StateType.paused);
                pauseRecording();
            }
        }

    };


    return (
        {
            "recording": (
                <IconButton
                    onClick={stopRecording}
                    disabled={!connection}
                >
                    <Stop/>
                </IconButton>
            ),
            "paused": (
                <IconButton
                    onClick={startRecording}
                    disabled={!connection}
                >
                    <Mic/>
                </IconButton>
            ),
            "error": (
                <IconButton
                    onClick={startRecording}
                    disabled={!connection}
                >
                    <MicOff/>
                </IconButton>
            )
        }[state]
    )
}
