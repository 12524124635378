import React, {useEffect, useRef, useState} from "react";
import {MainLayout} from "../../layouts/main/MainLayout";
import {
  Box,
  Button, Chip, Fade,
  IconButton,
  ListItemButton,
  Stack,
  Typography
} from "@mui/material";
import axios from "../../axios/axios";
import moment from "moment";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Chat} from "./Chat";
import {green, grey, red, teal} from "@mui/material/colors";
import {
  BugReport,
  Call, CallEnd,
  Delete,
  FiberManualRecord,
  Fullscreen, Mic, MicOff, OpenInFull,
  PlayArrow,
  VolumeMute,
  VolumeUp
} from "@mui/icons-material";
import {convertHMS} from "../../utils/convertToHMS";


export const Chats = () => {
  const [chats, setChats] = useState([]);
  const [session, setSession] = useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const [muteAudio, setMuteAudio] = useState(true);
  const [muteMicrophone, setMuteMicrophone] = useState(false);
  const [debug, setDebug] = useState(false);
  const [isCall, setIsCall] = useState(false);
  const [classicalView, setClassicalView] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [callTime, setCallTime] = useState(0);
  const interval = useRef(null);

  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    fetchChats();
  }, [])

  useEffect(() => {
    if (isCall) {
      interval.current = setInterval(() => {
        setCallTime(prevState => prevState + 1);
      }, 1000)
    } else if (interval.current) {
      clearInterval(interval.current);
      setCallTime(0);
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        setCallTime(0);
      }
    }
  }, [isCall])


  useEffect(() => {
    if (id) {
      setSelectedMessage(null);
      axios.get(`/api/session/${id}`)
        .then(response => {
          setSession(response?.data);
          setMuteAudio(!response?.data?.audio)
          console.log(response.data)
        })
        .catch((error) => console.log(error?.message))
    }
  }, [id])

  const fetchChats = () => {
    axios.get('/api/session')
      .then(response => {
        setChats(response?.data)
      })
      .catch((error) => console.log(error?.message))
  }

  const addChat = (item) => {
    setChats((prevState) => {
      return [item, ...prevState]
    })
  }

  const deleteChat = (deleteId) => {
    axios.delete(`/api/session/${deleteId}`)
      .then(response => {
        if (+deleteId === +id) {
          navigate("/");
        }
        fetchChats();
      })
      .catch((error) => console.log(error?.message))
  }

  const createSession = async (message) => {
    if (message) {
      try {
        const response = await axios.post(`/api/session`, {json: localStorage.getItem("json_url") || null})
        navigate(`/chat/${response?.data?.id}`);
        addChat(response.data);
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleTitleChange = (event) => {
    setEditTitle(false);
    const title = event.target.innerText;

    if (title !== session?.name) {
      axios.put(`/api/session/title`, {id: session.id, title: title})
        .then(response => updateSession(response.data))
        .catch(error => console.log(error))
    }
  }

  const updateSession = (item) => {
    setSession(item);
    const items = [...chats];
    const index = items.findIndex(i => i.id === item.id);
    if (isFinite(index) && index >= 0) {
      items[index] = item;
      setChats(items);
    }
  }

  const onToggleAudio = () => {
    axios.patch(`/api/session/audio/${id}`)
      .then(response => {
        setSession(response?.data);
        setMuteAudio(!response?.data?.audio)
      })
      .catch((error) => console.log(error?.message))
  }

  return (
    <MainLayout
      items={chats}
      mapItem={(item) => {
        return <ListItemButton
          key={item?.id}
          sx={{borderBottom: 0, borderColor: "divider", mb: 1, borderRadius: 2}}
          component={NavLink} to={`/chat/${item?.id}`}
        >
          <Stack sx={{width: "100%"}}>
            <Stack direction={"row"} sx={{width: "100%"}} justifyContent={"space-between"}
                   alignContent={"center"}>
              <Typography fontSize={10}
                          color={grey[500]}
                          fontWeight={"bold"}>{item?.id}</Typography>
              <Typography fontSize={10}
                          color={grey[500]}
                          align={"right"}
                          variant={"subtitle2"}>{moment(item.dateUpdated).format("DD.MM.YYYY")}</Typography>
            </Stack>
            <Stack direction={"row"} sx={{width: "100%"}}
                   justifyContent={"space-between"}
                   alignItems={"center"}
                   alignContent={"center"}>
              <Typography
                fontSize={13}
              >
                {item?.name?.substr(0, 20)} {item?.name?.length > 20 && "..."}
              </Typography>
              <IconButton onClick={() => deleteChat(item?.id)} size={"small"}><Delete
                sx={{fontSize: 18}}/></IconButton>

            </Stack>
          </Stack>
        </ListItemButton>
      }}
      primary={id ? <Stack direction={"row"} alignItems={"center"} gap={1}>
        <FiberManualRecord fontSize={"13"} sx={{color: green[400]}}/>
        <Typography
          contentEditable={editTitle}
          suppressContentEditableWarning={true}
          color={grey[700]}
          sx={{fontSize: "0.8m"}}
          onDoubleClick={() => setEditTitle(true)}
          onBlur={handleTitleChange}
        >
          {session?.name}
        </Typography>
      </Stack> : <Typography color={grey[700]}>Create or select chat</Typography>
      }
      secondary={
        <>
          {
            isCall && classicalView ?
              <>
                <Fade in={true} timeout={1000}>
                  <Chip sx={{bgcolor: green[400]}} size={"small"} color={"success"} label={convertHMS(callTime)}></Chip>
                </Fade>
                <Fade in={isCall && classicalView} timeout={1000}>
                  <IconButton onClick={onToggleAudio}>{muteAudio ? <VolumeMute/> : <VolumeUp/>}</IconButton>
                </Fade>
                <Fade in={isCall && classicalView} timeout={1000}>
                  <IconButton sx={{display: {xs: 'none', md: "flex"}}}
                              onClick={() => setMuteMicrophone(!muteMicrophone)}>{muteMicrophone ? <MicOff/> :
                    <Mic/>}</IconButton>
                </Fade>
                <Fade in={isCall && classicalView} timeout={1000}>
                  <IconButton onClick={() => setClassicalView(false)}><OpenInFull/></IconButton>
                </Fade>
                <Fade in={isCall && classicalView} timeout={1000}>
                  <IconButton sx={{color: red[400]}} onClick={() => setIsCall(false)}><CallEnd/></IconButton>
                </Fade>
              </>
              :
              <>
                <Fade in={true} timeout={1000}>
                  <IconButton sx={{color: green[400]}} onClick={() => {
                    setIsCall(true);
                    setClassicalView(false);
                  }}><Call/></IconButton>
                </Fade>
              </>

          }

          <IconButton sx={{color: debug ? green[400] : "default", display: {xs: 'none', md: "flex"}}}
                      onClick={() => setDebug(!debug)}><BugReport/></IconButton>
          <IconButton onClick={onToggleAudio}>{muteAudio ? <VolumeMute/> : <VolumeUp/>}</IconButton>
        </>
      }
    >

      {
        id ? <Chat id={id}
                   isMuted={muteAudio}
                   isMicrophoneMuted={muteMicrophone}
                   onClassicalView={() => setClassicalView(!classicalView)}
                   onMuteMicrophone={() => setMuteMicrophone(!muteMicrophone)}
                   onMuteAudio={onToggleAudio}
                   time={callTime}
                   classicView={classicalView}
                   addChat={addChat}
                   selectedMessage={selectedMessage}
                   debugMode={debug}
                   isCall={isCall}
                   endCall={() => setIsCall(false)}
                   onSelect={(message) => setSelectedMessage(message)}
          /> :
          <Box sx={{position: "relative", width: `100%`, height: "100%", p: 1}}>
            <Box className={"blured"}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}
                     sx={{width: "100%", height: `calc(100% - 80px)`}}>
                <Box
                  sx={{p: 3, m: 1, bgcolor: "light", border: 1, borderColor: "divider", boxShadow: 4, borderRadius: 2}}>
                  <Typography variant={"h6"}>
                    Would you like to start a conversation with an agent?
                  </Typography>
                  <Typography sx={{fontSize: 12, color: grey[500], mb: 1}}>
                    Click on the button below if you want to start, otherwise select a session from the menu.
                  </Typography>
                  <Button
                    size={"small"}
                    variant={"contained"}
                    onClick={createSession}
                    sx={{
                      color: "white",
                      fontSize: 12,
                      background: teal[300],
                      "&:hover": {
                        background: teal[400]
                      }
                    }}
                  >
                    <PlayArrow sx={{fontSize: 15}}/> Start new conversation
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
      }
    </MainLayout>)
}